import { render, staticRenderFns } from "./course-add.vue?vue&type=template&id=72fa50d8&scoped=true&"
import script from "./course-add.vue?vue&type=script&lang=js&"
export * from "./course-add.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72fa50d8",
  null
  
)

export default component.exports