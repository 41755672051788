<template>
  <el-dialog
      title="课程修改"
      width="55%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row :gutter="30">
        <el-col :span="7">
          <el-form-item label="课程名" prop="courseName">
            <el-input v-model="form.courseName" style="width:183px"/>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="课程类型" prop="courseType">
            <el-select v-model="form.courseType" placeholder="课程类型" style="width:183px">
              <el-option label="请选择课程类型" value=""/>
              <el-option label="线上课程" value="线上课程"/>
              <el-option label="线下课程" value="线下课程"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="7">
          <el-form-item label="开课日期" prop="startDate">
            <el-date-picker
                style="width:183px"
                v-model="form.startDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :default-value="form.startDate"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="结束日期" prop="endDate">
            <el-date-picker
                style="width:183px"
                v-model="form.endDate"
                value-format="yyyy-MM-dd"
                :default-value	="form.endDate"
                type="date"
                placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="7">
          <el-form-item label="开始时间">
            <el-select v-model="form.startTime" placeholder="开始时间" style="width:183px">
              <el-option
                  v-for="item in startTimeArr"
                  :key="item"
                  :label="item"
                  :value="item"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="课程价格" prop="price">
            <el-input type="number" v-model="form.price" style="width:183px"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="备注">
          <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4}"
              placeholder="请输入内容"
              v-model="form.remark">
          </el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addCompanyCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "course-edit",
  data() {
    return {
      form: {
        id:this.course.id,
        courseName: this.course.courseName,
        courseType: this.course.courseType,
        startDate: this.course.startDate,
        endDate: this.course.endDate,
        startTime: this.course.startTime,
        price: this.course.price,
        remark: this.course.remark,
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      startTimeArr:startTime,
      rules: {
        courseName: [
          {required: true, message: '请输入课程名称', trigger: 'blur'},
        ],
        courseType: [
          {required: true, message: '请选择课程类型', trigger: 'change'},
        ],
        startDate: [
          {required: true, message: '选择开始日期', trigger: 'change'},
        ],
        endDate: [
          {required: true, message: '选择结束日期', trigger: 'change'},
        ],
        price: [
          {required: true, message: '输入课程价格', trigger: 'blur'},
        ],

      },
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    course:{
      type:Object,
      required: true,
      default(){return{}},
    }
  },
  methods: {
    //检查订单校验
    addCompanyCheck: function () {
      console.log(this.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addCompany();
        }
      })
    },
    //添加订单
    addCompany: function () {
      this.$confirm("是否修改该课程?", '修改课程:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/trainCourse/updateTrainCourse",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
const startTime = [
  "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30",
  "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00",
]
</script>

<style scoped>

</style>