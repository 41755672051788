<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="courseName" placeholder="课程名称"></el-input>
        </el-col>
        <el-col :span="6">
          <el-date-picker
              v-model="startDateArray"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              start-placeholder="开课时间开始日期"
              end-placeholder="开课时间结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-select v-model="courseType" placeholder="课程类型">
            <el-option label="请选择课程类型" value=""/>
            <el-option label="线上课程" value="线上课程"/>
            <el-option label="线下课程" value="线下课程"/>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button @click="dialog.addCourseState = true" style="margin-bottom: 10px" type="primary">添加课程</el-button>
      <el-table
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.courseList"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          border
          v-loading="loading"
          height="650"
          max-height="650"
          :default-sort = "{prop: 'id', order: 'ascending'}"
          style="width: 100%">
        <el-table-column prop="courseName" label="课程名" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="courseType" label="课程类型" align="center" ></el-table-column>
        <el-table-column prop="startDate" label="开始日期" align="center" ></el-table-column>
        <el-table-column prop="endDate" label="结束日期" align="center" ></el-table-column>
        <el-table-column prop="startTime" label="开始时间" align="center" ></el-table-column>
        <el-table-column prop="price" label="价格" align="center" ></el-table-column>
        <el-table-column prop="isValid" label="是否有效" align="center" >
          <template slot-scope="scope">
            <el-switch
                @change="isValidChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" ></el-table-column>
        <el-table-column align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="warning" size="mini" @click="addEdit(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteCourse(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <courseAdd v-if="dialog.addCourseState" :state="dialog.addCourseState" @close="dialog.addCourseState=false" />
    <courseEdit v-if="dialog.editCourseState" :course="dialog.course" :state="dialog.editCourseState" @close="close" />
  </div>
</template>

<script>
import courseAdd from "@/pages/train/course/course-add";
import courseEdit from "@/pages/train/course/course-edit";

export default {
  name: "course-list",
  data(){
    return{
      courseName:"",
      startDate:"",
      courseType:"",
      startDateArray:[],
      tenantCrop:localStorage.getItem("tenantCrop"),
      table:{
        courseList:[],
        page:1,
        limit:100,
        total:0,
      },
      dialog:{
        addCourseState:false,
        editCourseState:false,
        course:{},
      },
      loading: false,
    }
  },
  components:{
    courseEdit,courseAdd,
  },
  created() {
    this.queryCourseList();
  },
  methods: {
    search() {
      this.table.page=1;
      this.queryCourseList();
    },
    //查询客户列表
    queryCourseList: function () {
      if (this.startDateArray.length===2){
        this.startDate=this.startDateArray[0]+" - "+this.startDateArray[1]
      }
      this.loading = true
      this.$axios({
        method: "GET",
        url: "/trainCourse/queryTrainCourseList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          courseName: this.courseName,
          startDate: this.startDate,
          courseType: this.courseType,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.courseList = response.data.data.list;
        this.loading = false
      })
    },
    isValidChange:function (value){
      this.$axios({
        method: "POST",
        url: '/trainCourse/updateTrainCourse',
        params: {
          "id": value.id,
          "isValid": value.isValid,
          "tenantCrop":this.tenantCrop
        }
      }).then(response => {
        let isValidState=response.data.code === 200;
        this.$message({
          message: isValidState ? "修改激活状态成功" : response.data.msg,
          type: isValidState ? "success" : 'error'
        });
      })
    },
    //打开添加员工界面
    addEdit(data) {
      this.dialog.editCourseState = true
      this.dialog.course = data
    },
    //删除客户
    deleteCourse(data) {
      this.$confirm("删除课程", '删除客户:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/trainCourse/deleteTrainCourse",
          params: {
            id: data.id
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self = this;
            setTimeout(function () {
              self.queryCourseList()
            }, 1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryCourseList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryCourseList()
    },
    close:function (state){
      this.dialog.editCourseState=state;
      this.queryCourseList();
    }
  }
}
</script>

<style scoped>

</style>